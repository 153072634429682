import { useQuery } from "react-query";
import supabase from "../../supabase";

export async function fetchQuoteImages(quote_id) {
  const quoteID = quote_id ? quote_id : 0;
  const { data, error } = await supabase
    .from("quote_images")
    .select("*")
    .order("id", { ascending: true })
    .match({ quote_id: quoteID });
  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export function useFetchQuoteImages(quote_id) {
  return useQuery("quote_images", () => fetchQuoteImages(quote_id));
}