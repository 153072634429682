import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import supabase from "../supabase";
import { useNotificationStore } from "../../store/notifications";

export async function archiveFileStatus(id, status) {
  const { data, error } = await supabase
    .from("GenericFiles")
    .update({
      status,
    })
    .match({ id });

  if (error) throw new Error(error.message);
  updateAppenateGenericFiles(data[0]);

  return data;
}

export function useArchiveFileStatus() {
  const { addNotification } = useNotificationStore();

  const queryClient = useQueryClient();

  return useMutation((id) => archiveFileStatus(id, false), {
    onSuccess: () => {
      queryClient.refetchQueries("GenericFiles");

      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: "Successfully archived file!",
      });
    },
  });
}


async function updateAppenateGenericFiles(file) {
  const { id, file_type, link,
    file_name, uploaded_by} = file;
  const filesPayload = [[
    id,
    file_type || '',
    link || '',
    file_name || '',
    uploaded_by || '',
    false // Change status to false to hide in Appenate.
  ]];

  console.log(filesPayload)

  return axios.post("https://vertex-server.herokuapp.com/api/data-sync", {
    id: "98a125a9-7129-4e21-bb85-afa901087ac1",
    data: filesPayload,
  });
}