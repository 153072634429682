import { useMutation } from "react-query";
import supabase from "../../supabase";

export async function updateQuoteHandover(handover) {
  const { data, error } = await supabase.from("quote_handover").upsert(handover);

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export const useUpdateQuoteHandover = () =>
  useMutation((handover) => updateQuoteHandover(handover), {
    onSuccess: (data) => data,
    onError: (error) => error,
    mutationFn: updateQuoteHandover,
  });
