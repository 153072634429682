import moment from "moment";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import supabase from "../../supabase";

export async function createQuote(quote) {

  const { data, error } = await supabase.from("quotes").insert(quote).select();

  await createAppenateQuote(data);

  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export const useCreateQuote = () =>
  useMutation((quote) => createQuote(quote), {
    onSuccess: (data) => data,
    onError: (error) => error,
    mutationFn: createQuote,
  });


async function createAppenateQuote(quote) {
  /*
    ROWS:
    id - job # - Client Id - Client Name - Client Contact # - Site - Job description - Status - Truck Driver - Supervisor -
  */

  const quotePayload = [];

  quote.map((quote) =>
    quotePayload.push([
      quote.quote_num,
      quote.description,
      quote.quote_num,
      moment(quote.created_at).format("DD/MM/YYYY"),
      quote.street_1,
      quote.status,
      quote.site_visited,
    ]),
  );

  return axios.post("https://vertex-server.herokuapp.com/api/data-sync", {
    id: "67f7f347-e03c-4b66-a4b3-b0ce002d9877",
    data: quotePayload,
  });
}