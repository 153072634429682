import React from "react";
import { CreateFile } from "./CreateFile";

export function QuotePhotos({ setFieldValue, data }) {

  return data ? (
    <div>
      <h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">Upload Site Photos</h2>
      <div className="flex items-center">
        <div>
          <h4 className="text-md font-normal leading-5 px-4">Photo - 1 </h4>
          <CreateFile
            field="quote_photo_1"
            setFieldValue={setFieldValue}
            type="edit"
            file={data[0]}
          />
        </div>
        <div>
          <h4 className="text-md font-normal leading-5 px-4">Photo - 2</h4>
          <CreateFile
            field="quote_photo_2"
            setFieldValue={setFieldValue}
            type="edit"
            file={data[1]}
          />
        </div>
      </div>

      <div className="flex items-center">
        <div>
          <h4 className="text-md font-normal leading-5 px-4">Photo - 3</h4>
          <CreateFile
            field="quote_photo_3"
            setFieldValue={setFieldValue}
            type="edit"
            file={data[2]}
          />
        </div>
        <div>
          <h4 className="text-md font-normal leading-5 px-4">Photo - 4</h4>
          <CreateFile
            field="quote_photo_4"
            setFieldValue={setFieldValue}
            type="edit"
            file={data[3]}
          />
        </div>
      </div>
      <br />
    </div>
  ) : null;
}