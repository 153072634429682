import { useQuery } from "react-query";
import supabase from "../supabase";

async function fetchAllActiveClients() {
  const { data, error } = await supabase.from("clients")
    .select("*")
    .eq('status', 'Active')
    .order("client_name", { ascending: true });

  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export const useActiveClients = () => useQuery({
  queryKey: ["active-clients"],
  queryFn: () => fetchAllActiveClients(),
});
