import { Card, Row, Button, Text, Modal, Image } from "@nextui-org/react";
import { useState } from "react";

export const Thumbnails = ({ link }) => {
  const [visible, setVisible] = useState(false);
  const handler = () => setVisible(true);
  const closeHandler = () => {
    setVisible(false);
    console.log("closed");
  };
  return (
    <div>
      <Card css={{ w: "300px", h: "300px" }}>
        <button type="button" onClick={() => setVisible(true)} className="w-full h-full">
          <Card.Body css={{ p: 0 }}>
            <Card.Image
              src={link}
              objectFit="contain"
              width="100%"
              height="100%"
              alt="Relaxing app background"
            />
          </Card.Body>
        </button>
        <Card.Footer
          isBlurred
          css={{
            position: "absolute",
            bgBlur: "#0f111466",
            borderTop: "$borderWeights$light solid $gray800",
            bottom: 0,
            zIndex: 1,
          }}
        >
          <Row className="flex justify-center">
            <Button
              flat
              auto
              rounded
              css={{ color: "#2590EB", bg: "white" }}
              onClick={() => window.open(link, "_blank")}
            >
              <Text css={{ color: "inherit" }} size={12} weight="bold" transform="uppercase">
                Open in a new tab
              </Text>
            </Button>
          </Row>
        </Card.Footer>
      </Card>
      <Modal noPadding open={visible} onClose={closeHandler}>
        <Modal.Body>
          <Image showSkeleton src={link} width={400} height={400} />
        </Modal.Body>
      </Modal>
    </div>
  );
};