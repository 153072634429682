import { JobsApi, WeeklyHireApi } from "../api";

const completedJob = async (jobID) => {
  const jobData = await JobsApi.fetchJob(jobID);
  if (jobData.on_hire === "Yes") {
    const areTasksCompleted = checkCompletedFields(jobData.job_tasks, "tasks");
    const hireData = await WeeklyHireApi.fetchHire(jobID);
    const areInvoicesCompleted = checkCompletedFields(hireData, "invoices");
    if (areTasksCompleted && areInvoicesCompleted) {
      await JobsApi.updateJob({ job: { on_hire: "No" }, jobId: jobID });
    }
  }
};

export default completedJob;

function checkCompletedFields(array, type) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].percentage_complete !== 100 && type === "tasks") {
      return false;
      // eslint-disable-next-line no-else-return
    } else if ((!array[i].completed_date || !array[i].date_on_hire) && type === "invoices") {
      return false;
    }
  }
  return true;
}
