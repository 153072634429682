/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import moment from "moment";
import { SideModal, Input, TextArea, Dropdown, DateSelect } from "../../../common";
import { JobsApi, ContactsApi } from "../../../api";
import completedJob from "../../../utils/completedJob";

const typeOptions = [
  { value: "Scaffolding", label: "Scaffolding" },
  { value: "Stairs", label: "Stairs" },
  { value: "Roof", label: "Roof" },
  { value: "Propping", label: "Propping" },
  { value: "Edge Protection", label: "Edge Protection" },
  { value: "Shrinkwrap", label: "Shrinkwrap" },
  { value: "Geda 1200", label: "Geda 1200" },
];

let RequesterOptions = [{ value: "Loading", label: "Loading" }];

export function UpdateTask({ taskId, jobId, open, setOpen, type = "tasks" }) {
  const updateTaskMutation = JobsApi.useUpdateTask();

  JobsApi.fetchJob(jobId).then(data => {
    ContactsApi.fetchAllContacts(data.client_id).then(contacts => {
      RequesterOptions = contacts.map(e => ({ value: e.name, label: e.name }));
    })
  })
  const tasksQuery = JobsApi.useFetchTask(taskId);

  if (tasksQuery.isLoading) {
    return (
      <div />
    );
  }

  if (!tasksQuery.data) return null;

  return (
    <div>
      <Formik
        initialValues={{
          PO_Number: tasksQuery.data.PO_Number || "",
          zone: tasksQuery.data.zone || "",
          zone_label: tasksQuery.data.zone_label || "",
          type: tasksQuery.data.type || "",
          description: tasksQuery.data.description || "",
          percentage_complete: tasksQuery.data.percentage_complete || 0,
          percentage_erect: tasksQuery.data.percentage_erect || 0,
          percentage_dismantle: tasksQuery.data.percentage_dismantle || 0,
          total_hours: tasksQuery.data.total_hours || "",
          Requester: tasksQuery.data.Requester || "",
          LastEditDate: tasksQuery.data.LastEditDate || moment().format("DD/MM/YYYY")
        }}
        onSubmit={async (values, { resetForm }) => {
          console.log(values.LastEditDate)
          const hours = Number(values.total_hours);
          const fixed = hours.toFixed(2);
          const taskPayload = {
            job_id: Number(jobId),
            PO_Number: values.PO_Number || "",
            zone: values.zone,
            zone_label: values.zone_label,
            type: values.type,
            description: values.description,
            percentage_erect: Number(values.percentage_erect) || 0,
            percentage_dismantle: Number(values.percentage_dismantle) || 0,
            percentage_complete: ((Number(values.percentage_erect) * 0.7) + (Number(values.percentage_dismantle) * 0.3)) || 0,
            total_hours: String(fixed),
            Requester: values.Requester || "",
            LastEditDate: moment(values.LastEditDate, "DD/MM/YYYY").format("DD/MM/YYYY") || ""
          };
          console.log('taskPayload', taskPayload)
          try {
            await updateTaskMutation.mutateAsync({
              payload: taskPayload,
              taskId,
              type: "task",
            });
            completedJob(jobId)
            resetForm();
            setOpen(false);
          } catch (err) {
            console.log("ERROR CREATING JOB", err);
          }
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <SideModal
            heading="Update Task"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            isLoading={isSubmitting}
            formType="edit"
          >
            <div className="flex items-center">
              <Input
                title="PO Number"
                id="PO_Number"
                type="text"
                readOnly
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.PO_Number}
              />
              <Dropdown
                label="Requester"
                id="Requester"
                readOnly
                options={RequesterOptions}
                error={errors.Requester}
                value={values.Requester}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </div>
            <div className="">
              <TextArea
                title="Description"
                id="description"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.description}
              />
            </div>
            <div className="flex items-center">
              <Input
                title="Percentage Erect"
                id="percentage_erect"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.percentage_erect}
              />
              <Input
                title="Percentage Dismantle"
                id="percentage_dismantle"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.percentage_dismantle}
              />

            </div>

            <div className="flex items-center">
              <Input
                title="Percentage Complete"
                id="percentage_complete"
                type="number"
                handleChange={() => null}
                handleBlur={() => null}
                value={((Number(values.percentage_erect) * 0.7) + (Number(values.percentage_dismantle) * 0.3)).toFixed(2) || 0}
              />
            </div>
            <div className="flex items-center">
              <Input
                title="Total Hours"
                id="total_hours"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.total_hours}
              />
              <DateSelect
                title="Last Edit Date"
                id="LastEditDate"
                value={values.LastEditDate}
                onChange={setFieldValue}
              />
            </div>
          </SideModal>
        )}
      </Formik>
    </div>
  );
}
