import React from "react";

import { Container, HeadingContainer, StaffContainer, TaskContainer } from "./styledComponents";

const EventView = ({ event }) => {
  const color = {
    '':'#10B981',
    null: "#10B981",
    Install: "#10B981",
    Dismantle: "#0078d4",
    Adjustment: "#EF4444",
    Remedial: "#fbb724",
  };
  const eventProp = event.extendedProps;
  
  const styles = () => ({
    color: "white",
    backgroundColor: color[eventProp.type],
  });

  return (
    <Container
      padding="0.5px"
      fontSize="0.8rem"
      color={styles().color}
      backgroundColor={styles().backgroundColor}
    >
      <HeadingContainer>{event.title}</HeadingContainer>
      <TaskContainer> <b>Job Number:</b> {eventProp.jobNum} </TaskContainer>
      <TaskContainer> <b>Suburb:</b> {eventProp.suburb} </TaskContainer>
      <TaskContainer> <b>Client:</b> {eventProp.client} </TaskContainer>

    </Container>
  );
};

export default EventView;
