import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
// import { Header, Row, Footer } from "./index";
import { Header } from "./Header/Header";
import { Row } from "./Row";
import { Footer } from "./Footer";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 2,
    marginBottom: 20,
  },
});

export const Table = ({ type = "", invoice, columns, groupable }) => (
  <View style={styles.tableContainer}>
    <Header columns={columns} />
    <>
      <Row items={invoice} groupable={groupable} />
      {/*  <Footer items={invoice} /> */}
    </>
    {/* <Footer items={invoice.items} /> */}
  </View>
);
