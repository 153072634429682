/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { SideModal, Input, TextArea, Dropdown, Address, DateSelect } from "../../../common";

import { JobsApi, ContactsApi, WeeklyHireApi } from "../../../api";
import supabase from "../../../api/supabase";
import completedJob from "../../../utils/completedJob";

const typeOptions = [
  { value: "Install", label: "Install" },
  { value: "Dismantle", label: "Dismantle" },
  { value: "Adjustment", label: "Adjustment" },
  { value: "Remedial", label: "Remedial" },
];

let RequesterOptions = [{ value: "Loading", label: "Loading" }];

export function CreateVariation({ jobId, open, setOpen }) {
  const createTaskMutation = JobsApi.useCreateVariation();
  const createHireMutation = WeeklyHireApi.useCreateHire();

  JobsApi.fetchJob(jobId).then((data) => {
    ContactsApi.fetchAllContacts(data.client_id).then((contacts) => {
      RequesterOptions = contacts.map((e) => ({ value: e.name, label: e.name }));
    });
  });

  return (
    <div>
      <Formik
        initialValues={{
          PO_Number: "",
          zone: "",
          zone_label: "",
          type: "",
          description: "",
          percentage_erect: 0,
          percentage_dismantle: 0,
          total_hours: "",
          Requester: "",
          task_value_usd: 0,
          weekly_hire_rate: 0,
        }}
        onSubmit={async (values, { resetForm }) => {
          const loggedInuser = await supabase.auth.user();
          const name = loggedInuser?.user_metadata?.name;

          const hours = Number(values.total_hours);
          const fixed = !Number.isNaN(hours) ? hours.toFixed(2) : 0;
          const taskPayload = {
            job_id: Number(jobId),
            PO_Number: values.PO_Number || "",
            task_type: "Variation",
            zone: values.zone,
            zone_label: values.zone_label,
            type: values.type,
            description: values.description,
            percentage_erect: Number(values.percentage_erect) || 0,
            percentage_dismantle: Number(values.percentage_dismantle) || 0,
            percentage_complete:
              Number(values.percentage_erect) * 0.7 + Number(values.percentage_dismantle) * 0.3 ||
              0,
            total_hours: String(fixed),
            Requester: values.Requester || "",
            created_by: name || "",
            task_value_usd: Number(values.task_value_usd) || 0,
          };
          const taskPayloadHire = {
            job_id: Number(jobId),
            zone: values.zone,
            zone_label: values.zone_label,
            type: values.type,
            description: values.description,
            completed: Number(values.percentage_erect) || 0,
            weekly_hire_rate: Number(values.weekly_hire_rate) || 0,
            on_hire: "No",
          };
          try {
            const result = await createTaskMutation.mutateAsync(taskPayload);
            await createHireMutation.mutateAsync({ ...taskPayloadHire, task_id: result[0].id });
            completedJob(jobId)
            setOpen(false);
            resetForm();
          } catch (err) {
            console.log("ERROR CREATING JOB", err);
          }
        }}
        validate={(values) => {
          const errors = {};

          if (!values.PO_Number) errors.PO_Number = "PO Number Is Required.";
          if (!values.Requester) errors.Requester = "Requester Is Required.";
          // if (!values.zone) errors.zone = "Zone Is Required.";
          // if (!values.zone_label) errors.zone_label = "Zone Label Is Required.";
          // if (!values.type) errors.type = "Type Is Required.";
          if (!values.description) errors.description = "Description Is Required.";
          if (!values.total_hours) errors.total_hours = "Total Hours Is Required.";
          if (!values.task_value_usd) errors.task_value_usd = "Task Value Is Required.";
          if (!values.weekly_hire_rate) errors.weekly_hire_rate = "Weekly Hire Rate Is Required.";
          return errors;
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
        }) => (
          <SideModal
            heading="Create Variation Task"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
            isLoading={isSubmitting}
            formType="create"
          >
            <div className="flex items-center">
              <Input
                title="PO Number"
                id="PO_Number"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.PO_Number}
                value={values.PO_Number}
              />
              <Dropdown
                label="Requester"
                id="Requester"
                options={RequesterOptions}
                error={errors.Requester}
                value={values.Requester}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </div>

            <div className="w-1/2">
              <Dropdown
                label="Type"
                id="type"
                options={typeOptions}
                error={errors.type}
                value={values.type}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </div>

            <div className="">
              <TextArea
                title="Description"
                id="description"
                type="text"
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.description}
                value={values.description}
              />
            </div>

            <div className="flex items-center">
              <Input
                title="Total Hours"
                id="total_hours"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.total_hours}
                value={values.total_hours}
              />
              <Input
                title="Value"
                id="task_value_usd"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.task_value_usd}
                value={values.task_value_usd}
              />
            </div>

            <div className="w-1/2">
              <Input
                title="Weekly Hire Rate"
                id="weekly_hire_rate"
                type="number"
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.weekly_hire_rate}
                value={values.weekly_hire_rate}
              />
            </div>
          </SideModal>
        )}
      </Formik>
    </div>
  );
}
