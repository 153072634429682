import { useState } from "react";
import moment from "moment";
import axios from "axios";
import { DateSelect, Button, ConfirmationDialog } from "../../common";
import { WeeklyHireApi, EDInvoices, InvoicesApi } from "../../api";
import { useNotificationStore } from "../../store/notifications";

export const EndOfMonth = ({ invoicesSelected, setStandardCompleteDate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [date, setDate] = useState(moment().format("DD/MM/YYYY"));
  const updateHireMutation = WeeklyHireApi.useUpdateHire();
  const createHireMutation = WeeklyHireApi.useCreateHire();
  const updateEDInvoiceMutation = EDInvoices.useUpdateEDInvoice();
  const createInvoiceRegister = InvoicesApi.useCreateInvoiceRegister();
  const { addNotification } = useNotificationStore();

  const endOfMouth = async () => {
    if (!invoicesSelected) {
      return;
    }

    if (!invoicesSelected) {
      return;
    }
    const url = "https://vertex-server.herokuapp.com/api/myob/sendInvoice";
    setTimeout(async () => {
      const uniqueJobs = Array.from(new Set(invoicesSelected.map((item) => item.job_id)));
      await uniqueJobs.map(async (jobID) => {
        const invoices = invoicesSelected.filter((e) => e.job_id === jobID);
        const invoice = {
          lineItems: invoices,
          jobs: invoices[0].jobs, // TODO: This is ugly I hate it but it will work.
        }
        try {
          const response = await axios.post(url, {
            invoice,
            access_code: localStorage.getItem("access_code"),
            date,
          });

          addNotification({
            isSuccess: true,
            heading: "Success!",
            content: `Successfully approved invoice.`,
          });
          setIsDone(true);

          invoices.map(async (invoice) => {
            if(invoice.InvoiceType === "Weekly Charge")
            {
              console.log("invoiceinvoice", {
                status: "Approved",
                completed_date: invoice.completed_date
                  ? moment(invoice.completed_date).format('DD/MM/YYYY')
                  : date,
                  myob_invoice_number: response.data.Number,
                  myob_reference: response.data.UID,
              });

              await updateHireMutation.mutateAsync({
                hire: {
                  status: "Approved",
                  completed_date: invoice.completed_date
                    ? moment(invoice.completed_date).format('DD/MM/YYYY')
                    : date,
                    myob_invoice_number: response.data.Number,
                    myob_reference: response.data.UID,
                },
                hireId: invoice.id,
              });
              console.log(invoice,"invoice")
              await createHireMutation.mutateAsync({
                ...invoice,
                date_on_hire: moment(date, "DD/MM/YYYY").add(1, "days").format("DD/MM/YYYY"),
                completed_date: "",
              });
            }
            else
            {
              await updateEDInvoiceMutation.mutateAsync({
                payload: {
                  status: "Approved",
                  myob_invoice_number: response.data.Number,
                  myob_reference: response.data.UID,
                },
                id: invoice.id,
              });
              await createInvoiceRegister.mutateAsync({
                id: invoice.id,
              });
            }
          });

        } catch (error) {
          addNotification({
            isSuccess: false,
            heading: "Error!",
            content: `An error has occurred. The invoice was not created`,
          });
          console.error("Error:", error);
        }
      });

      setTimeout(() => {
        setStandardCompleteDate(moment().format("DD/MM/YYYY"));
        setIsLoading(false);
        setIsDone(true);
      }, uniqueJobs.length * 5000);
    }, 1000);
  };

  const handleInputChange = (id, val) => {
    setDate(moment(val).format("DD/MM/YYYY"));
    setStandardCompleteDate(moment(val).format("DD/MM/YYYY"));
  };
  return (
    <ConfirmationDialog
      isDone={isDone}
      icon="info"
      title="End Of Month"
      body="This action will create a new Weekly Charge Invoices. Select A Date for the process"
      triggerButton={
        <Button
          label="End Of The Month"
          style={{ backgroundColor: "#0078d4", borderRadius: "4px" }}
          icon="submit"
          className="p-button-raised p-3 flex align-items-center justify-content-center"
        >
          End Of The Month
        </Button>
      }
      confirmButton={
        <Button
          isLoading={isLoading}
          variant="approve"
          onClick={async (e) => {
            setIsLoading(true);
            endOfMouth();
            setIsLoading(false);
          }}
        >
          End Of The Month
        </Button>
      }
    >
      <div className="flex">
        <DateSelect title="Date End Of Month" id="date" value={date} onChange={handleInputChange} />
        <br />
      </div>
    </ConfirmationDialog>
  );
};
