import { useMutation, useQueryClient } from "react-query";
import supabase from "../supabase";

async function createMYOBAuth(payload) {
  const { data: selectData, error: selectError } = await supabase
    .from("myob")
    .select("*")
    .eq("access_code", payload.access_code);

  if (!selectData.length) {
    const { data: insertData, error: insertError } = await supabase.from("myob").insert(payload);
    if (insertError) {
      throw new Error(insertError?.message);
    }
    return insertData;
  }

  if (selectError) {
    throw new Error(selectError?.message);
  }
  const { data: updateData, error: updateError } = await supabase
    .from("myob")
    .update(payload)
    .match({ access_code: payload.access_code });

  if (updateError) {
    throw new Error(updateError.message);
  }
  return updateData;
}

export function useCreateMYOBAuth() {
  const queryClient = useQueryClient();

  return useMutation((payload) => createMYOBAuth(payload), {
    onSuccess: () => {
      queryClient.refetchQueries("myob");
    },
  });
}
