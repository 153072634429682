import React from "react";
import { Text, View, Font, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  headerContainer: {
    marginTop: 25,
  },
  heading: {
    fontFamily: "Open Sans",
    fontSize: 8,
    textAlign: "justify",
    marginBottom: 8,
  },
  description: {
    fontFamily: "Open Sans",
    fontSize: 8,
    marginBottom: 12,
  },

  job_site: {
    fontFamily: "Open Sans",
    fontSize: 8,
    marginVertical: 20,
  },
});

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: 600,
    },
  ],
});

export const Description = ({ description, job_site }) => (
  <View style={styles.headerContainer}>
    <View>
      <Text style={styles.heading}>Description:</Text>
      <Text style={styles.description}>{description}</Text>
    </View>
    <View>
      <Text style={styles.heading}>Job Site:</Text>
      <Text style={styles.description}>{job_site}</Text>
    </View>
  </View>
);
