import { useMutation, useQueryClient } from "react-query";
import supabase from "../supabase";

import { useNotificationStore } from "../../store/notifications";

async function createInvoiceRegister(invoice) {
  const { data, error } = await supabase.from("invoices_register").insert(invoice);

  if (error) {
    throw new Error(error.messge);
  }
  return data;
}

export function useCreateInvoiceRegister() {
  const { addNotification } = useNotificationStore();

  const queryClient = useQueryClient();

  return useMutation((invoice) => createInvoiceRegister(invoice), {
    onSuccess: () => {
      queryClient.refetchQueries("invoices_register");
    },
    mutationFn: createInvoiceRegister,
  });
}