import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import axios from "axios";
import { Textarea, Modal, Text } from "@nextui-org/react";
import { Badge } from "../Badges";
import { useCreateMYOBAuth } from "../../api/MYOB";

export function XeroStatus() {
  const [MYOBStatus, setMYOBStatus] = useState(false);

  const updateMYOBAuth = useCreateMYOBAuth();
  useEffect(() => {
    const url = "https://vertex-server.herokuapp.com/api/myob/handleToken";

    const getTokenPayload = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      grant_type: "authorization_code",
      code: localStorage.getItem("access_code"),
      redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    };
    const refreshTokenPayload = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      grant_type: "refresh_token",
      code: localStorage.getItem("access_code"),
      refresh_token: JSON.parse(localStorage.getItem("myob_token"))?.data?.refresh_token,
    };

    if (!JSON.parse(localStorage.getItem("myob_token"))?.data?.access_token) {
      axios
        .post(url, getTokenPayload)
        .then((response) => {
          const accessToken = response;
          console.log("REEEEEEEES", response);
          localStorage.setItem("myob_token", JSON.stringify(accessToken));
          setMYOBStatus(true);
          sendToken();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      axios
        .post(url, refreshTokenPayload)
        .then((response) => {
          const accessToken = response;
          console.log("REEEEEEEES", response);
          localStorage.setItem("myob_token", JSON.stringify(accessToken));
          setMYOBStatus(true);
          sendToken();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

    const changeStatus = () => {
      setMYOBStatus(false);
    };

    const intervalId = setInterval(changeStatus, 1200000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const sendToken = async () => {
    console.log("sendToken");
    const data = JSON.parse(localStorage.getItem("myob_token"))?.data;
    try {
      await updateMYOBAuth.mutateAsync({
        access_code: localStorage.getItem("access_code"),
        token: data.access_token,
        refresh_token: data.refresh_token,
      });
    } catch (err) {
      console.log("ERROR APPROVING", err);
    }
  };

  const client_id = process.env.REACT_APP_CLIENT_ID;
  const redirect_uri = process.env.REACT_APP_REDIRECT_URI;
  const url = `https://secure.myob.com/oauth2/account/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&scope=CompanyFile`;
  return (
    <>
      <div className="flex items-center pl-6">
        {MYOBStatus && localStorage.getItem("access_code") ? (
          <Badge type="Active" text="MYOB Status: Active" />
        ) : (
          <Badge type="Issue" text="MYOB Status: In Error, Login required" />
        )}
      </div>
      <br />
      <div className="flex items-center pl-6">
        {MYOBStatus && localStorage.getItem("access_code") ? (
          <Button label="Refresh" onClick={() => window.location.reload()} style={{width:100,height:40, borderRadius:3}}/>
        ) : (
          <Button label="Login With MYOB" onClick={() => window.open(url, "_blank")} />
        )}
      </div>
    </>
  );
}
