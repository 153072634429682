import React, { useState, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/outline";
import { Line } from "./Line";

import { Spinner } from "../../../common";
import { QuotesApi } from "../../../api";

import { useNotificationStore } from "../../../store/notifications";
import supabase from "../../../api/supabase";
import { fetchClient } from "../../../api/Clients";

const initialLine = {
  id: null,
  zone: "1",
  zoneLabel: "",
  type: "",
  description: "",
  quantity: 1,
  lengthMeasurement: "",
  height: "",
  width: "",
  totalDimensions: "",
  dismantle: "",
  percentageWeeklyHireFee: 7,
  hireFee: "",
  total: 0,
};

export const QuoteLines = ({
  quoteLines,
  setQuoteLines,
  zoneOptions,
  zoneLabels,
  rates,
  formType,
  estimatedWay,
  clientId
}) => {
  const deleteQuoteLineMutation = QuotesApi.useDeleteLine();
  const { addNotification } = useNotificationStore();
  const [discount, setDiscount] = useState("")

  useEffect(() => {
    if (clientId) {
      fetchClient(clientId).then(({ client_type }) => {
        if (client_type === "Builders") {
          setDiscount(0.76);
        } else if (client_type === "Painters") {
          setDiscount(0.71);
        } else if (client_type === "Roofers") {
          setDiscount(0.67);
        } else {
          setDiscount(1);
        }
      });
    }
  }, [clientId]);


  // Recalculate values on rate change

  useEffect(() => {
    let isCurrent = true;
    if (isCurrent) {
      const update = quoteLines.map((line) => {
        const rateData=rates.find(e => e.service === line.type)
        const scaffoldingRate = rateData?.erect_fee;
        const erectDismantleFee = Number(scaffoldingRate * line.quantity);
        const weekHireFee = Number(erectDismantleFee * line.percentageWeeklyHireFee).toFixed(2);
        let total = (Number(erectDismantleFee) + Number(weekHireFee));
        if(rateData?.service==="Scaffold Erection and Dismantle"){
          total *= discount;
        }
        return {
          ...line,
          dismantle: erectDismantleFee,
          hireFee: weekHireFee,
          total,
        };
      });

      setQuoteLines(update);
    }
    return () => {
      isCurrent = false;
    };
  }, [rates]);

  const handleAddLine = () => {
    setQuoteLines([...quoteLines, initialLine]);
  };

  const handleRemoveLine = async (id, quoteId) => {
    setQuoteLines(quoteLines.filter((line, index) => index !== id));

    // If form type is edit, send an api call to delete line by id.
    if (formType === "edit") {
      console.log("DELETING LINES", quoteId);
      try {
        await deleteQuoteLineMutation.mutateAsync(quoteId);

        addNotification({
          isSuccess: true,
          heading: "Success!",
          content: `Successfully remove quote line`,
        });
      } catch (err) {
        console.log("ERROR DELETING", err);

        addNotification({
          isSuccess: false,
          heading: "Failed!",
          content: `Failed to remove quote line`,
        });
      }
    }
  };

  const handleDimensionsLineChange = (
    index,
    quantity,
    rateData,
    percentageWeeklyHireFee
  ) => {
    if (quantity && estimatedWay === "Hours") {
      const scaffoldingRate = rateData?.erect_fee;
      let erectDismantleFee = Number(scaffoldingRate * quantity);
      if(erectDismantleFee<=1250){
        erectDismantleFee = 1250
      }
      const weekHireFee = Number(erectDismantleFee * (Number(percentageWeeklyHireFee) / 100)).toFixed(2);
      let total = (Number(erectDismantleFee) + Number(weekHireFee))
      if(rateData?.service==="Scaffold Erection and Dismantle"){
        total *= discount;
      }
       
      const totalDays = Number(quantity / 8).toFixed(1);

      setQuoteLines(
        quoteLines.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              dismantle: erectDismantleFee,
              hireFee: weekHireFee,
              totalDays,
              total,
            };
          }
          return item;
        }),
      );
      console.log(quoteLines)
    }
  };

  const handleEDChangeLineChange = (
    index,
    dismantle,
    rateData,
    percentageWeeklyHireFee
  ) => {
    if (dismantle && estimatedWay === "Total ED") {
      const scaffoldingRate = rateData?.erect_fee;
      const quantity = (Number(dismantle) / scaffoldingRate).toFixed(2);
      const hireFee = (Number(dismantle) * (Number(percentageWeeklyHireFee) / 100)).toFixed(2);
      let total = (Number(dismantle) + Number(hireFee));
      if(rateData?.service==="Scaffold Erection and Dismantle"){
        total *= discount;
      }
      const totalDays = Number(quantity / 8).toFixed(1);

      setQuoteLines(
        quoteLines.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              quantity,
              totalDays,
              hireFee,
              total,
            };
          }
          return item;
        }),
      );
    }
  };

  const handleDimensionsChangeLineChange = (
    index,
    quantity,
    dismantle,
    rateData,
    lengthMeasurement,
    height,
    width,
    percentageWeeklyHireFee,
  ) => {
    if (quantity && estimatedWay === "SqM") {
      const totalDimensions = Number(lengthMeasurement * height * width);
      const scaffoldingRate = rateData?.erect_fee;
      let erectDismantleFee = Number(scaffoldingRate * quantity * totalDimensions);
      if(erectDismantleFee<=1250){
        erectDismantleFee = 1250
      }
      const weekHireFee = Number(erectDismantleFee * (Number(percentageWeeklyHireFee) / 100)).toFixed(2);
      const total = (Number(erectDismantleFee) + Number(weekHireFee));
      const totalDays = Number(quantity / 8).toFixed(1);

      setQuoteLines(
        quoteLines.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              dismantle: erectDismantleFee,
              hireFee: weekHireFee,
              totalDays,
              total,
            };
          }
          return item;
        }),
      );
      console.log(quoteLines)
    }
  };

  const handleProductLineChange = (index, name, value) => {
    setQuoteLines(
      quoteLines.map((item, i) => {
        if (i === index) {
          return { ...item, [name]: value };
        }
        return item;
      }),
    );
  };


  const columns = estimatedWay === "SqM" ? [
    "Type",
    "Description",
    "Quantity",
    "Length",
    "Height",
    "Width",
    "Total Dimensions",
    "Erect & Dismantle (p/u)",
    "Weekly Hire Fee (%)",
    "Weekly Hire Fee (p/u)",
    "Total",
    "",
  ] : [
    "Type",
    "Description",
    "Quantity",
    "Total Days",
    "Erect & Dismantle (p/u)",
    "Weekly Hire Fee (%)",
    "Weekly Hire Fee (p/u)",
    "Total",
    "",
  ];

  return (
    <div>
      <div className="w-full">
        <h2 className="text-lg leading-6 font-sm uppercase text-gray-700 my-4">
          Scaffolding, Propping & Optional Extras
        </h2>
        <table className="w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              {columns.map((column) => (
                <>
                  <th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider">
                    {column}
                  </th>
                </>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 w-8">
            {quoteLines.map((line, index) => (
              <Line
                quoteLine={line}
                handleRemove={handleRemoveLine}
                index={index}
                handleProductLineChange={handleProductLineChange}
                handleDimensionsLineChange={handleDimensionsLineChange}
                handleEDChangeLineChange={handleEDChangeLineChange}
                handleDimensionsChangeLineChange={handleDimensionsChangeLineChange}
                zoneOptions={zoneOptions}
                zoneLabels={zoneLabels}
                rates={rates}
                estimatedWay={estimatedWay}
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-6 mb-16">
        <button type="button" className="flex items-center" onClick={handleAddLine}>
          <PlusIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
          <span className="ml-2 text-sm">Add Item</span>
        </button>
      </div>
    </div>
  );
};