import { StyleSheet, Font } from "@react-pdf/renderer";

const borderColor = "#D1D5DB";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: 600,
    },
  ],
});

export const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    flexDirection: "row",
    paddingVertical: 2,
    alignItems: "center",
    textAlign: "left",
    color: "#1F2937",
    fontFamily: "Open Sans",
    fontSize: 7.4,
    fontWeight: "semibold",
  },
  description: {
    textWrap: "nowrap",
    textOverflow: "ellipsis",
    flexDirection: "column",
    width: "50%",
    textAlign: "left",
    paddingLeft: 8,
    paddingRight: 3,
  },
  invoiceType: {
    textWrap: "nowrap",
    textOverflow: "ellipsis",
    flexDirection: "column",
    width: "20%",
    textAlign: "left",
    paddingLeft: 8,
    paddingRight: 3,
  },
  quantity: {
    width: "15%",
    textAlign: "center",
    paddingLeft: 8,
    paddingRight: 3,
  },
  total: {
    width: "15%",
    textAlign: "right",
    paddingLeft: 8,
    paddingRight: 3,
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },
});
