import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { useState } from "react";
import moment from "moment";
import { Input, TextArea, Button, ConfirmationDialog } from "../../common";
import { QuotesApi, WeeklyHireApi, JobsApi } from "../../api";
import supabase from "../../api/supabase";
import { useNotificationStore } from "../../store/notifications";
import { uploadQuote } from "./pdf/uploadQuote";


export const EmailSent = ({ quoteId, emailStatus, quote }) => {
  // const [isLoading, setIsLoading] = useState(false);
  // const updateQuoteMutation = QuotesApi.useUpdateQuote();
  const { addNotification } = useNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const updateQuoteMutation = QuotesApi.useUpdateQuote();

  console.log('quote: ', quote);
  const clientContact = quote.client_contacts?quote.client_contacts.name: ' ';

  const [state, setState] = useState({
    subject: `Quote ${quote.quote_num}`,
    body: `Hi ${clientContact}
Please click the link below to view our quote for ${quote.street_1} ${quote.city}.
To accept the quote please email ${quote.staff?.staff_name} at ${quote.staff?.email} 
with the quote number ${quote.quote_num}.

This quote is valid for 30 days.
Please do not reply directly to this email.`,
});

  const newStatus = emailStatus === "Ready to send" ? "Sent" : "Ready to send";

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setState({
        ...state,
        [id]: value,
    });
};

  return (
    <ConfirmationDialog
      isDone={updateQuoteMutation.isSuccess}
      icon="info"
      title="Sent Quote"
      body={`Are you sure you wish to mark this quote as ${newStatus} ? ${newStatus === 'Sent' ? "This action will send the quote to the client" : ''}`}
      triggerButton={
        <button
          type="button"
          id={quoteId}
          className="ml-3 inline-flex items-center text-sm font-medium focus:outline-none hover:text-green-400"
        >
          {emailStatus === "Ready to send" && < CheckIcon
            className="-ml-0.5 mr-2 h-4 w-4 text-green-400"
            aria-hidden="true"
          />}
          {emailStatus === "Sent" && <XIcon
            className="-ml-0.5 mr-2 h-4 w-4 text-red-400"
            aria-hidden="true"
          />}
          {emailStatus}
        </button >
      }
      confirmButton={
        <Button
          isLoading={isLoading}
          variant="approve"
          onClick={async (e) => {
            if (!state.body || !state.subject) {
                            return
                        }          
            setIsLoading(true)
            try {
              const result = await updateQuoteMutation.mutateAsync({
                quote: { emailStatus: newStatus },
                quoteId,
              })
              console.log(result)
            } catch (error) {
              console.log("error")
            }
            setIsLoading(false);
            if (newStatus === "Sent") {
                const urlQuote = await uploadQuote(quoteId);

                console.log('url: ', urlQuote);
                console.log('State: ', state);

                let htmlText = '';
                const messageTextArray = state.body.split('\n');

                messageTextArray.forEach(line => {
                  htmlText += `<p>${line}</p>`
                })

                            fetch("https://vertex-server.herokuapp.com/api/quote-email", {
                                method: 'POST', // or 'PUT'
                                body: JSON.stringify({
                                    subject: state.subject,
                                    text: `${htmlText} <a href="${urlQuote}">Link</a>`,
                                    emailTo: quote?.client_contacts?.email,
                                    sender: quote?.staff?.email || null,
                                }),
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            }).then(res => res.json())
                                .catch(error => addNotification({
                                    isSuccess: false,
                                    heading: "Error!",
                                    content: `Network Error With Xero.`,
                                }))
                                .then(async response => {
                                    let isSuccess = true;
                                    let content = "Success!";
                                    if (response.err) {
                                        isSuccess = false;
                                        content = "Error!";
                                    }
                                    const heading = response.message;
                                    
                                    addNotification({
                                        isSuccess,
                                        heading: 'Sent email',
                                        content: 'Sent email!!!'
                                    });
                                    try {
                                        const result = await updateQuoteMutation.mutateAsync({
                                            quote: { emailStatus: newStatus },
                                            quoteId,
                                        })

                                        window.location.reload();
                                    } catch (error) {
                                        console.log("error")
                                    }
                                    setIsLoading(false);
                                })
                        } else {
                          console.log('else..')
                            try {
                                const result = await updateQuoteMutation.mutateAsync({
                                    quote: { emailStatus: newStatus },
                                    quoteId,
                                })
                                console.log(result)
                                window.location.reload();
                            } catch (error) {
                                console.log("error")
                            }
                            setIsLoading(false);
                        }
          }}
        >
          Mark as {newStatus}
        </Button >
      }
    >
      <>
          {newStatus === 'Sent' && <>
              <div className="flex">
                  <div className="w-1/2">
                      <Input
                          title="Subject"
                          id="subject"
                          type="text"
                          value={state.subject}
                          handleChange={handleInputChange}
                          error={!state.subject?"Subject Is Requied":null}
                      />
                  </div>
              </div>
              <TextArea
                  title="Body Text"
                  id="body"
                  type="text"
                  value={state.body}
                  handleChange={handleInputChange}
                  error={!state.body?"Body Is Requied":null}
              />
          </>}
      </>
    </ConfirmationDialog>
  );
};
