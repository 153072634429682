import { useQuery } from "react-query";
import supabase from "../../supabase";

export async function fetchQuote(id) {
  const { data, error } = await supabase
    .from("quote_handover")
    .select(
      `*`,
    )
    .eq("quotes_id", Number(id));
  if (error) {
    throw new Error(error.message);
  }
  return data[0];
}

export function useFetchQuoteHandover(quoteId) {
    return useQuery({
      queryKey: ["quote_handover", quoteId],
      queryFn: () => fetchQuote(quoteId),
    });
}
