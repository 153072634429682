import { pdf } from "@react-pdf/renderer";
import moment from "moment";
import supabase from "../../api/supabase";
import { fetchQuote } from "../../api/Quotes";
import { DownloadPDF } from "./DownloadPDF";
import { createAppFile } from "../../api/Files/createAppFiles";
import { createFile } from "../../api/Files";

export async function UploadInvoices(invoices, description, myob_invoice_number, date) {
  const quoteData = await fetchQuote(invoices[0].jobs.quote_id);

  let url = "";
  let blob;

  const changedInvoiecs = invoices.map((invoice) => ({
    ...invoice,
    description:
      invoice.InvoiceType === "Weekly Charge"
        ? `${invoice.description}  (${moment(invoice.date_on_hire).format("DD/MM/YYYY")} - ${moment(
            invoice.completed_date,
          ).format("DD/MM/YYYY")})`
        : invoice.description,
  }));

  try {
    blob = await pdf(
      <DownloadPDF
        invoices={changedInvoiecs}
        quote={quoteData}
        description={description}
        date={date}
        myob_invoice_number={myob_invoice_number}
      />,
    ).toBlob();
  } catch (error) {
    console.log(error);
  }

  const fileName = `${myob_invoice_number} - ${moment().unix()}`;

  const { data, error } = await supabase.storage.from("files").upload(`quotes/${fileName}`, blob, {
    cacheControl: "3600",
    upsert: false,
  });
  if (!error) {
    const fetchFile = await supabase.storage.from("files").getPublicUrl(`quotes/${fileName}`);
    url = fetchFile.publicURL;
    await createFile({
      job_id: invoices[0].jobs.id,
      file_type: "Invoice file",
      file: fetchFile.publicURL,
      file_description: `Invoice ${myob_invoice_number} sent to Email: ${invoices[0].jobs?.clients?.email} on ${date}`,
    });
  }
  return {
    url: encodeURI(url),
    subject: `Invoice - ${myob_invoice_number}`,
    body: `Hi ${invoices[0].jobs?.clients?.client_name},
To view your invoice click the link below:
<a href="${url}">Open Invoice</a>`,
    sendTo: invoices[0].jobs?.clients?.email,
    sender: "aaron@vertexscaffolding.nz" || null,
  };
}
