/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { SideModal, Input, Dropdown } from "../../common";
import { useCreateGenericFile } from "../../api/Files";
import { CreateGenericFile } from "./CreateGenericFileInput";
import supabase from "../../api/supabase";

const typeOptions = [
    { value: 'Encapsulations', label: 'Encapsulations' },
    { value: 'Propping', label: 'Propping' },
    { value: 'Roofing', label: 'Roofing' },
    { value: 'Spouting', label: 'Spouting' },
];

export function CreateGenericFiles({ heading, open, setOpen, formType = "create" }) {

    const createFileMutation = useCreateGenericFile();

    const user = supabase.auth.user();

    return (
        <div>
            <Formik
                initialValues={{
                    file_type: "",
                    file_name: "",
                    link: ""
                }}
                validate={(values) => {
                    const errors = {};

                    if (!values.file_type) {
                        errors.file_type = "File Type Is requied.";
                    }
                    if (!values.file_name) {
                        errors.file_name = "File Name Is requied.";
                    }

                    if (!values.link) {
                        errors.link = "File is required.";
                    }

                    return errors;
                }}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    const {
                        file_type,
                        file_name,
                        link
                    } = values;

                    const filePayload = {
                        file_type,
                        file_name,
                        link,
                        uploaded_by: user?.user_metadata?.name || "",
                    };

                    try {
                        await createFileMutation.mutateAsync(filePayload)
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.log(error)
                    }

                    setSubmitting(false);
                    setOpen(false);
                    resetForm();
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    setFieldTouched,
                }) => (
                    <SideModal
                        heading={heading}
                        open={open}
                        setOpen={setOpen}
                        handleSubmit={handleSubmit}
                        isLoading={isSubmitting}
                        formType={formType}
                    >
                        <>
                            <div className="flex items-center">
                                <Dropdown
                                    label="File Type"
                                    id="file_type"
                                    value={values.file_type}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    options={typeOptions}
                                    error={errors.file_type}
                                />
                                <Input
                                    title="File Name"
                                    id="file_name"
                                    type="text"
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    value={values.file_name}
                                    error={errors.file_name}
                                />
                            </div>
                            <div className="flex items-center">
                                <CreateGenericFile field="link" setFieldValue={setFieldValue} type="edit" error={errors.link} />
                            </div>
                        </>
                    </SideModal>
                )}
            </Formik>
        </div>
    );
}