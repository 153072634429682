import React from "react";
import moment from "moment";
import { Text, Image, View, Font, StyleSheet } from "@react-pdf/renderer";

import logo from "../../../logo.jpg";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf",
      fontWeight: 600,
    },
  ],
});

export const ClientInfo = ({ quote, invoice }) => {
  const fields = [[quote.street_1], [quote.street_2], [quote.city]];
  const addressFormat = fields
    .map((part) => part.filter(Boolean).join(" "))
    .filter((str) => str.length)
    .join(", ");

  const client = invoice?.jobs?.clients?.client_name;
  const clientAddress = invoice?.jobs?.clients?.site;

  const street1 = quote?.street_1;
  const street2 = quote?.street_2;
  const city = quote?.city;
  const postCode = quote?.post_code;
  return (
    <View style={styles.headingContainer}>
      <View>
        <View>
          <Text style={styles.subText}>{client || " "}</Text>
        </View>
        <View>{clientAddress ? <Text style={styles.subText}>{clientAddress}</Text> : null}</View>
        <View />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  headingContainer: {
    /* marginRight: 20, */
    width: "100%",
    // fontSize: 12,
  },
  clientContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
  },
  addressContainer: {
    textAlign: "left",
    marginTop: 20,
    paddingRight: 8,
  },
  headingContact: {
    textAlign: "left",
    marginTop: -20,
    paddingRight: 9,
    marginRight: 9,
  },
  headingDisplay: {
    display: "flex",
    flexDirection: "row",
  },
  headingText: {
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 9,
  },
  subText: {
    fontFamily: "Open Sans",
    marginLeft: 4,
    fontSize: 9,
    // fontWeight: "semibold",
  },
  subText2: {
    fontFamily: "Open Sans",
    fontSize: 6,
    color: "red",
    // fontWeight: "semibold",
  },
  logo: {
    objectFit: "contain",
    width: "25%",
    height: "auto",
    paddingTop: 15,
  },
});
