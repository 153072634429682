import React from "react";
import { Document, Page, Text, StyleSheet, View } from "@react-pdf/renderer";
import moment from "moment";
import { Heading } from "./Heading/Heading";
import { ClientInfo } from "./Heading/ClientInfo";
import { Description } from "./Description";
import { Table } from "./Table/Table";
import { invoiceColumns } from "./columns";
import { numberFormat } from "../../utils";

export function DownloadPDF({ invoices, quote, description, myob_invoice_number, date }) {
  const saleAmount = invoices
    .map((item) => Number(item.total) || Number(item.ed_total))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const GST = saleAmount * 0.15;
  const PO_Numbers = [...new Set(invoices.map((invoice) => invoice.PO_Number))]
    .sort((a, b) => a - b)
    .join(", ");
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Heading quote={quote} invoice={invoices[0]} />
        <View style={styles.quoteTextContainer}>
          <Text style={styles.quoteText}>TAX INVOICE/STATEMENT</Text>
          <Text style={styles.quoteText}>Vertex Scaffolding Ltd.</Text>
        </View>
        <View style={styles.invoiceInfoContainer}>
          <View style={{ display: "flex", alignItems: "flex-end" }}>
            <Text style={styles.invoiceInfoContainerSubTextBold}>
              GST Number:
              <Text style={styles.invoiceInfoContainerSubText}>119-972-965</Text>
            </Text>
            <Text style={styles.invoiceInfoContainerSubTextBold}>
              GST Invoice No:
              <Text style={styles.invoiceInfoContainerSubText}>{myob_invoice_number}</Text>
            </Text>
            <Text style={styles.invoiceInfoContainerSubTextBold}>
              Date:
              <Text style={styles.invoiceInfoContainerSubText}>
                {moment(date, "DD/MM/YYYY").format("Do MMMM YYYY")}
              </Text>
            </Text>
            <Text style={styles.invoiceInfoContainerSubTextBold}>
              Customer Purchase Order:
              <Text style={styles.invoiceInfoContainerSubText}>{PO_Numbers}</Text>
            </Text>
          </View>
        </View>
        <View style={{ paddingLeft: 50 }}>
          <ClientInfo quote={quote} invoice={invoices[0]} />
        </View>
        <Description description={description} job_site={invoices[0].jobs.clients.site} />
        <Table invoice={invoices} columns={invoiceColumns} />

        <View>
          <View style={styles.paymentRow}>
            <View style={styles.paymentTitle}>
              <Text style={styles.paymentTitleText}>
                Payment Due within 7 Days from date of invoice
              </Text>
            </View>
            <View style={styles.paymentAmount}>
              <Text style={{ ...styles.subText, marginRight: 5 }}>SALE AMOUNT</Text>
              <Text style={styles.subText}>{numberFormat.format(saleAmount)}</Text>
            </View>
          </View>
          <View style={styles.paymentRow}>
            <View style={styles.paymentTitle}>
              <Text style={styles.paymentTitleText}>Payment can be made by Direct Credit to</Text>
            </View>
            <View style={styles.paymentAmount}>
              <Text style={{ ...styles.subText, marginRight: 5 }}>GST</Text>
              <Text style={styles.subText}>{numberFormat.format(GST)}</Text>
            </View>
          </View>
          <View style={styles.paymentRow}>
            <View style={styles.paymentTitle}>
              <Text style={styles.paymentTitleText}>Kiwi Bank 38-9020-0579735-00</Text>
            </View>
            <View style={styles.paymentAmount}>
              <Text style={{ ...styles.subText, marginRight: 5 }}>TOTAL AMOUNT</Text>
              <Text style={styles.subText}>{numberFormat.format(saleAmount + GST)}</Text>
            </View>
          </View>
        </View>

        <View style={styles.remittanceAdviceContainer}>
          <Text style={{ ...styles.subTextBold, marginBottom: 15 }}>REMITTANCE ADVICE</Text>
          <View style={styles.remittanceAdviceSubContainer}>
            <View style={styles.borderedClientInfo}>
              <ClientInfo quote={quote} invoice={invoices[0]} />
            </View>
            <View style={styles.invoiceInfoContainer}>
              <View>
                <Text style={{ ...styles.subText, textAlign: "right" }}>
                  GST Invoice No: {myob_invoice_number}
                </Text>
                <Text style={{ ...styles.subText, textAlign: "right" }}>
                  Date: {moment(date, "DD/MM/YYYY").format("Do MMMM YYYY")}
                </Text>
                <Text style={{ ...styles.subText, textAlign: "right" }}>
                  Total Amount Due: {numberFormat.format(saleAmount + GST)}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    // paddingTop: 10,
    paddingBottom: 40,
    paddingLeft: 50,
    paddingRight: 50,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: 150,
    alignItems: "left",
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 9,
    color: "#1A8140",
    marginTop: 8,
  },
  headingContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headingContact: {
    textAlign: "right",
    marginTop: 20,
  },
  headingDisplay: {
    display: "flex",
    flexDirection: "row",
  },
  headingText: {
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 7,
  },
  quoteText: {
    fontFamily: "Open Sans",
    fontWeight: "semibold",
    fontSize: 12,
  },
  subText: {
    fontFamily: "Open Sans",
    fontSize: 8,
    textAlign: "justify",
    // fontWeight: "semibold",
  },
  subTextMargin: {
    fontFamily: "Open Sans",
    fontSize: 7,
    marginTop: 5,
    // fontWeight: "semibold",
  },
  subTextBold: {
    fontFamily: "Open Sans",
    marginLeft: 4,
    marginTop: 15,
    fontSize: 10,
    fontWeight: "bold",
  },
  subTextBoldRed: {
    fontFamily: "Open Sans",
    color: "red",
    marginLeft: 4,
    marginTop: 15,
    fontSize: 7,
    fontWeight: "bold",
    textAlign: "right",
  },
  logo: {
    objectFit: "contain",
    width: "20%",
    height: "auto",
    paddingTop: 20,
  },
  footer: {
    flexDirection: "row",
    position: "absolute",
    bottom: 7,
    right: 0,
  },
  pageTermsOfTrade: {
    objectFit: "contain",
    width: "105%",
    height: "auto",
    marginLeft: "-20",
  },
  footerLogo: {
    objectFit: "contain",
    width: "110%",
    // height: "auto",
    marginRight: 0,
  },

  footerText: {
    fontSize: 6,
    paddingLeft: 10,
  },

  pageNumber: {
    // position: "absolute",
    marginTop: 10,
    marginRight: 17,
    // paddingLeft: 70,
    fontSize: 8,
    // bottom: 30,
    // left: 0,
    // right: 0,
    textAlign: "center",
    color: "grey",
  },

  quoteTextContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingLeft: 50,
  },

  invoiceInfoContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: 5,
  },

  invoiceInfoContainerSubText: {
    fontFamily: "Open Sans",
    fontSize: 8,
    textAlign: "right",
    fontWeight: "normal",
  },
  invoiceInfoContainerSubTextBold: {
    fontFamily: "Open Sans",
    fontSize: 8,
    fontWeight: "bold",
    textAlign: "right",
  },
  paymentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  paymentTitle: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "50%",
  },
  paymentTitleText: {
    fontFamily: "Open Sans",
    fontWeight: "bold",
    fontSize: 8,
  },
  paymentAmount: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "50%",
    paddingLeft: 50,
  },

  remittanceAdviceContainer: {
    borderTopWidth: 1,
    borderColor: "black",
    borderStyle: "dotted",
    marginTop: 20,
  },
  borderedClientInfo: {
    borderWidth: 1,
    borderColor: "black",
    width: "40%",
  },

  remittanceAdviceSubContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
