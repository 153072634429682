import { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { DateSelect, Button, ConfirmationDialog, TextArea } from "../../common";
import { useNotificationStore } from "../../store/notifications";
import { WeeklyHireApi, InvoicesApi, JobsApi, EDInvoices } from "../../api";
import { Input } from "../../common/Form/Input";
import { UploadInvoices } from "../Invoices/UploadInvoices";

export const ApproveInvoices = ({ invoicesSelected, setInvoicesSelected }) => {
  const { addNotification } = useNotificationStore();
  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [date, setDate] = useState(moment().format("DD/MM/YYYY"));
  const [scoutValue, setScoutValue] = useState("");
  const [jobSelect, setJobSelect] = useState("");
  const [description, setDescription] = useState();

  const updateWeeklyHireMutation = WeeklyHireApi.useUpdateHire();
  const updateEDInvoiceMutation = EDInvoices.useUpdateEDInvoice();
  const createInvoiceRegister = InvoicesApi.useCreateInvoiceRegister();

  const handleTextChange = (event) => {
    setDescription(event.target.value);
  };
  useEffect(() => {
    if (invoicesSelected?.length > 0) {
      JobsApi.fetchJob(invoicesSelected[0].job_id).then((data) => {
        setJobSelect(data);
        setDescription(data.descriptionOfQuote);
      });
    }
  }, [invoicesSelected]);

  const approveInvoices = async () => {
    if (!invoicesSelected) {
      return;
    }
    setTimeout(async () => {
      const uniqueJobs = Array.from(new Set(invoicesSelected.map((item) => item.job_id)));
      console.log(invoicesSelected, "uniqueJobs");
      console.log("Date: ", date);
      await uniqueJobs.map(async (jobID) => {
        const invoices = invoicesSelected.filter((e) => e.job_id === jobID);
        const invoice = {
          lineItems: invoices,
          jobs: invoices[0].jobs, // TODO: This is ugly I hate it but it will work.
        };
        try {
          const response = await axios.post(
            "https://vertex-server.herokuapp.com/api/myob/sendInvoice",
            {
              invoice,
              access_code: localStorage.getItem("access_code"),
              date,
            },
          );
          // TODO: Make this detect failures
          invoices.map(async (invoice) => {
            if (invoice.InvoiceType === "Weekly Charge") {
              await updateWeeklyHireMutation.mutateAsync({
                hire: {
                  status: "Approved",
                  completed_date: invoice.completed_date
                    ? moment(invoice.completed_date).format("DD/MM/YYYY")
                    : date,
                  myob_invoice_number: response.data.Number,
                  myob_reference: response.data.UID,
                },
                hireId: invoice.id,
              });
              await createInvoiceRegister.mutateAsync({
                invoice_id: invoice.id,
              });
            } else {
              await updateEDInvoiceMutation.mutateAsync({
                payload: {
                  status: "Approved",
                  myob_invoice_number: response.data.Number,
                  myob_reference: response.data.UID,
                },
                id: invoice.id,
              });
              await createInvoiceRegister.mutateAsync({
                id: invoice.id,
              });
            }
          });
          console.log('XeroInvoice:');
          console.log(response?.data);
          const { url, body, subject, sendTo, sender } = await UploadInvoices(
            invoices,
            description,
            response.data.Number,
            date,
          );

          console.log("url: ", url);

          let htmlText = "";
          const messageTextArray = body.split("\n");

          messageTextArray.forEach((line) => {
            htmlText += `<p>${line}</p>`;
          });

          fetch("https://vertex-server.herokuapp.com/api/invoice-email", {
            method: "POST", // or 'PUT'
            body: JSON.stringify({
              subject,
              text: `${htmlText}`,
              emailTo: sendTo,
              sender,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((res) => res.json())
            .catch((error) =>
              addNotification({
                isSuccess: false,
                heading: "Error!",
                content: `Error sending email.`,
              }),
            )
            .then(async (response) => {
              let isSuccess = true;
              let content = "Success!";
              if (response?.err) {
                isSuccess = false;
                content = "Error!";
              }
              const heading = response.message;

              addNotification({
                isSuccess,
                heading: "Success",
                content: "Email sent",
              });
            });

          addNotification({
            isSuccess: true,
            heading: "Success",
            content: `Invoice created in MYOB`,
          });
          setCompleted(true);
        } catch (error) {
          addNotification({
            isSuccess: false,
            heading: "Error!",
            content: `An error has occurred. The invoice was not created`,
          });
          console.error("Error:", error);
        }
      });

      setTimeout(() => {
        setCompleted(true);
        setInvoicesSelected([]);
      }, uniqueJobs.length * 5000); // TODO: Make this actually detect when the invoice response comes back
    }, 1000);
  };

  const handleInputChange = (id, val) => {
    setDate(moment(val).format("DD/MM/YYYY"));
  };

  return (
    jobSelect && (
      <ConfirmationDialog
        isDone={completed}
        icon="info"
        title="Approve Invoices"
        body="This action will create an invoice with the selected line items in MYOB.Enter the invoice date"
        triggerButton={
          <Button
            label="Approve Weekly Hire Invoices"
            style={{ backgroundColor: "#0078d4", borderRadius: "4px" }}
            icon="submit"
            className="p-button-raised p-3 flex align-items-center justify-content-center"
          >
            Approve Invoices
          </Button>
        }
        confirmButton={
          <Button
            isLoading={isLoading}
            variant="approve"
            onClick={async (e) => {
              setIsLoading(true);
              approveInvoices();
              setIsLoading(false);
            }}
          >
            Approve Invoices
          </Button>
        }
      >
        <div className="text-area-container">
          <DateSelect
            title="Approve Invoices"
            id="date"
            value={date}
            onChange={handleInputChange}
          />
          {/* <label className=" ml-4" htmlFor="text-area">
            Description
          </label> */}
          <div>
            <TextArea
              title="Description"
              id="description"
              type="text"
              handleChange={handleTextChange}
              value={description}
            />
          </div>
          <br />
        </div>
      </ConfirmationDialog>
    )
  );
};
