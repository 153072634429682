import { useMutation } from "react-query";
import supabase from "../../supabase";


async function createHandover(payload) {
  const { data, error } = await supabase.from("quote_handover").insert(payload);

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export function useCreateQuoteHandover() {
  return useMutation((payload) => createHandover(payload), {
    mutationFn: createHandover,
  });
}
