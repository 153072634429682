import { useQuery } from "react-query";
import supabase from "../supabase";

export async function fetchEdInvoices(id) {
  const { data, error } = await supabase
    .from("edinvoices")
    .select(
      `*,
      job:job_id(job_status)`,
    )
    .eq("job_id", id);
  if (error) {
    throw new Error(error.message);
  }
  // const filteredInvoices = data.filter((invoice) => {
  //   if (
  //     (invoice.job.job_status === "Built & On Hire" || invoice.job.job_status === "In Progress") &&
  //     invoice.description.includes("Erect Cost (70%)")
  //   ) {
  //     invoice.complete_percent = invoice.complete_percent ? invoice.complete_percent : 0;
  //     return true;
  //   }
  //   if (
  //     invoice.job.job_status === "Completed" &&
  //     invoice.description.includes("Dismantle Cost (30%)")
  //   ) {
  //     invoice.complete_percent = invoice.complete_percent ? invoice.complete_percent : 0;
  //     return true;
  //   }
  //   if (
  //     !invoice.description.includes("Dismantle Cost (30%)") &&
  //     !invoice.description.includes("Erect Cost (70%)")
  //   ) {
  //     invoice.complete_percent = invoice.complete_percent ? invoice.complete_percent : 0;
  //     return true;
  //   }
  //   return false;
  // });
  return data;
}

export function useFetchEdInvoices(job_id) {
  return useQuery({
    queryKey: ["job_id", job_id],
    queryFn: () => fetchEdInvoices(job_id),
  });
}
