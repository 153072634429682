import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import supabase from "../supabase";

export async function createFile(payload) {
  const { data, error } = await supabase.from("files").insert(payload);

  if (error) {
    throw new Error(error.message);
  }
  updateAppenateJobsFiles(data[0]);
  return data;
}

export function useCreateFile() {
  const queryClient = useQueryClient();

  return useMutation((payload) => createFile(payload), {
    onSuccess: () => {
      queryClient.refetchQueries("files");
    },
  });
}
async function updateAppenateJobsFiles(file) {
  const { id, file: link, file_type, job_id } = file;
  const filesPayload = [[id, link || "", file_type || "", job_id || ""]];

  return axios.post("https://vertex-server.herokuapp.com/api/data-sync", {
    id: "fbdea9e4-a704-4783-b5fd-afb2017c3e3b",
    data: filesPayload,
  });
}
