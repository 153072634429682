import { useQuery } from "react-query";
import supabase from "../supabase";

export async function fetchAllEdInvoices() {
  const { data, error } = await supabase
    .from("edinvoices")
    .select("*, jobs:job_id(id, job_num, job_num, site, branding, quote_id,job_status, clients:client_id( * ))")
    .order("job_id", { ascending: false });

    // const filteredInvoices = data.filter((invoice) => {
    //   if (
    //     (invoice.jobs?.job_status === "Built & On Hire" || invoice.jobs?.job_status === "In Progress") &&
    //     invoice.description.includes("Erect Cost (70%)")
    //   ) {
    //     invoice.complete_percent = invoice.complete_percent ? invoice.complete_percent : 0;
    //     return true;
    //   }
    //   if (
    //     invoice.jobs.job_status === "Completed" &&
    //     invoice.description.includes("Dismantle Cost (30%)")
    //   ) {
    //     invoice.complete_percent = invoice.complete_percent ? invoice.complete_percent : 0;
    //     return true;
    //   }
    //   if (
    //     !invoice.description.includes("Dismantle Cost (30%)") &&
    //     !invoice.description.includes("Erect Cost (70%)")
    //   ) {
    //     invoice.complete_percent = invoice.complete_percent ? invoice.complete_percent : 0;
    //     return true;
    //   }
    //   return false;
    // });
  if (error) {
    throw new Error(error.message);
  }

  return data;
}
export function useEDInvoices() {
  return useQuery("EDInvoices", () => fetchAllEdInvoices());
}