import { useEffect, useState } from "react";
import { ScrollPanel } from 'primereact/scrollpanel';
import { classNames } from "../../../utils";


export function AdditionalConditionsQuote({
  id,
  sharedCheckBoxesState,
  labelInline = false,
  title,
  error,
}) {
  const [additioanConditions, setAdditioanConditions] = useState([]);
  let items = [];

  useEffect(() => {
    if (sharedCheckBoxesState) {
      setAdditioanConditions(sharedCheckBoxesState);
    }
  }, sharedCheckBoxesState);

  if (sharedCheckBoxesState) {
    items = sharedCheckBoxesState.filter((element) => element.checked === true);
  }

  return (
    <div
      className={classNames(
        labelInline
          ? "space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5"
          : "block",
        "w-full px-4 py-4 relative z-0",
      )}
    > 
      <ScrollPanel style={{ width: '100%', height: '600px'}} className="additional-condition-bar">
        <div>
          <label htmlFor={id} className="block text-sm font-medium text-gray-700">
            {title}
          </label>
        </div>
        <div className="sm:col-span-2">
          {items.map((line, index) => (          
            <div key={index} className="sm:col-span-2">
              {(line.name !== "paymentCondition1" && line.name !== "paymentCondition2") && (
                <div>
                  <div className="sm:col-span-2">
                    <p className="mt-2 text-sm">
                      <b>{line.title}</b>
                    </p>
                  </div>

                  <div>
                    <p className="mt-2 text-sm">{line.text} </p>
                  </div>
                </div>
              )}
              </div>
          ))}
          <br />

          <p className="mt-2 text-sm">
            The job must be done in two stages as scaffolding needs to be built on the lower roof for edge protection on the top roof.
          </p> 

          <p className="mt-2 text-sm">
            Windows will be kept clear of scaffolding, but where not possible, the scaffolding may need to be adjusted which will be charged at $90.00/per man/per hour plus GST.
          </p> 

          <br />

          <p className="mt-2 text-sm">
              The following condition apply to this job:
          </p> 
          {items.map((line, index) => (          
            <div key={index} className="sm:col-span-2">
              {(line.name === "paymentCondition1" || line.name === "paymentCondition2") && (
                <div>
                  <div className="sm:col-span-2">
                    <p className="mt-2 text-sm">
                      * <b>{line.title}</b>
                    </p>
                  </div>

                  <div>
                    <p className="mt-2 text-sm">{line.text} </p>
                  </div>
                </div>
              )}
              </div>
          ))}

          <br />

          {/* <ul>
            <li>
              <p className="mt-2 text-sm">
                * Our condition on acceptance of this quote is that we will require a 100% upfront payment of the EDC.
              </p>              
            </li>
            <li>
             <p className="mt-2 text-sm">
              * EDC payment to be paid prior to commencement of erecting of scaffold. Balance to be paid 7 days after invoice has been sent upon removal of scaffolding.
              </p>
            </li>
          </ul> */}

          <p className="mt-2 text-sm">
            Terms:
          </p>

          <p className="mt-2 text-sm">
            Hire charges start on the date on which the erection of the scaffolding is completed and continue until the date of dismantling of the scaffolding as advised by the client (off-hire date). Both dates will be charged as whole days.
          </p>

          <p className="mt-2 text-sm">
            Alterations or extra work will be charged at $90.00 plus GST/hour/man plus hire charges for additional scaffolding material. Extra work includes but is not limited to all modifications, moves, repairs, partly dismantling or adjustments of the scaffolding.
          </p>

          <p className="mt-2 text-sm">
            Please confirm your acceptance of this Quote and enclosed Terms of Trade either online or via email.
          </p>

  
        </div>
      </ScrollPanel>

      
      {error && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
            {error}
        </p>
       )}
    </div>
  );
}
