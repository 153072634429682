import React, { useState } from 'react';

function Tooltip(props) {
  const [isVisible, setIsVisible] = useState(false);

  function showTooltip() {
    setIsVisible(true);
  }

  function hideTooltip() {
    setIsVisible(false);
  }

  return (
    <div className="tooltip-wrapper relative">
      <div className="inline-block" onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
        {props.children}
      </div>
      {isVisible && (
        <div className="tooltip absolute z-10 bg-blue-600 text-white text-sm py-3 px-3 rounded-md">
          {props.text}
          <span className="tooltip-arrow absolute bg-blue-600 w-3 h-3 transform rotate-45"> </span>
        </div>
      )}
    </div>
  );
}

export default Tooltip;