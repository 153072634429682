import { useQuery } from "react-query";
import supabase from "../supabase";

async function fetchAllGenericFiles() {

    const { data, error } = await supabase
        .from("GenericFiles")
        .select("*")
        .order("id", { ascending: false });

    if (error) {
        throw new Error(error.message);
    }
    return data;
}

export function useFetchGenericFiles() {
    return useQuery("GenericFiles", () => fetchAllGenericFiles());
}
