import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import supabase from "../supabase";

async function createGenericFile(payload) {

  const { data, error } = await supabase
    .from("GenericFiles").insert(payload);

  if (error) {

    throw new Error(error.message);
  }
  updateAppenateGenericFiles(data[0])
  return data;
}

export function useCreateGenericFile() {
  const queryClient = useQueryClient();

  return useMutation((payload) => createGenericFile(payload), {
    onSuccess: () => {
      queryClient.refetchQueries("genericFiles");
    },
  });
}


async function updateAppenateGenericFiles(file) {
  const { id, file_type, link,
    file_name, uploaded_by, status} = file;
  const filesPayload = [[
    id,
    file_type || '',
    link || '',
    file_name || '',
    uploaded_by || '',
    status || ''
  ]];

  console.log(filesPayload)

  return axios.post("https://vertex-server.herokuapp.com/api/data-sync", {
    id: "98a125a9-7129-4e21-bb85-afa901087ac1",
    data: filesPayload,
  });
}