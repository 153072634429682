
import moment from "moment";
import axios from "axios";import { useMutation, useQueryClient } from "react-query";
import { useNotificationStore } from "../../../store/notifications";
import supabase from "../../supabase";

export async function updateQuote({ quote, quoteId }) {
  const { data, error } = await supabase.from("quotes").update(quote).match({ id: quoteId }).select();

  await createAppenateQuote(data);

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export function useUpdateQuote() {
  const { addNotification } = useNotificationStore();

  const queryClient = useQueryClient();

  return useMutation((quote) => updateQuote(quote), {
    onSuccess: () => {
      queryClient.refetchQueries("quotes");

      addNotification({
        isSuccess: true,
        heading: "Success!",
        content: "Successfully updating quote!",
      });
    },
  });
}

async function createAppenateQuote(quote) {
  /*
    ROWS:
    id - job # - Client Id - Client Name - Client Contact # - Site - Job description - Status - Truck Driver - Supervisor -
  */

  const quotePayload = [];

  quote.map((quote) =>
    quotePayload.push([
      quote.quote_num,
      quote.description,
      quote.quote_num,
      moment(quote.created_at).format("DD/MM/YYYY"),
      quote.street_1,
      quote.status,
      quote.site_visited,
    ]),
  );

  return axios.post("https://vertex-server.herokuapp.com/api/data-sync", {
    id: "67f7f347-e03c-4b66-a4b3-b0ce002d9877",
    data: quotePayload,
  });
}