import { useQuery } from "react-query";
import supabase from "../supabase";

export async function fetchStaffByEmail(email) {
  const { data, error } = await supabase
    .from("staff")
    .select("*")
    .match({email})

  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export function useStaffByEmail(email) {
  return useQuery("staff", () => fetchStaffByEmail());
}