import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";

const Questions = ({ handleChange, data }) => {
  const questions = [
    { id: 1, question: "SSSP required", answer: null },
    { id: 2, question: "Parking arranged by the client", answer: null },
    { id: 3, question: "Neighbours’ permission to build", answer: null },
    { id: 4, question: "Trees/bushes must be trimmed", answer: null },
  ];

  const [answers, setAnswers] = useState(data);
  const [allAnswers, setAllAnswers] = useState(false);
  useEffect(() => {
    checkFields();
  }, []);

  const checkFields = () => {
    const areAllFieldsNotNull = answers.every((obj) => {
      if (!obj) return false;
      return Object.values(obj).every((value) => value !== null);
    });
    if (areAllFieldsNotNull) {
      handleChange(answers);
    }
    if (answers.length === 4) {
      setAllAnswers(areAllSpacesBusy(answers));
    }
  };

  function areAllSpacesBusy(answers) {
    for (let i = 0; i < answers.length; i++) {
      if (!answers[i]) {
        return false;
      }
    }
    return true;
  }

  return (
    <div>
      {!allAnswers ? <p className="mb-2 text-sm text-red-600">All questions are required</p> : null}
      {questions.map((question, index) => (
        <div>
          <p>{question.question}</p>
          <div className="flex gap-4 my-2">
            <div>
              <RadioButton
                inputId={`${question.question}Yes`}
                name={`${question.question}Yes`}
                value={!false}
                onChange={(e) => {
                  const answer = answers;
                  answer[index] = { ...question, answer: e.target.value };
                  setAnswers(answer);
                  checkFields();
                }}
                checked={answers[index]?.answer}
              />
              <label htmlFor={`${question.question}Yes`} className="p-radiobutton-label ml-1">
                Yes
              </label>
            </div>
            <div>
              <RadioButton
                inputId={`${question.question}No`}
                name={`${question.question}No`}
                value={false}
                onChange={(e) => {
                  const answer = answers;
                  answer[index] = { ...question, answer: e.target.value };
                  setAnswers(answer);
                  checkFields();
                }}
                checked={answers[index]?.answer === false && true}
              />
              <label htmlFor={`${question.question}No`} className="p-radiobutton-label ml-1">
                No
              </label>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Questions;
