import React, { useEffect } from "react";
import logo from "../../logo.jpg";

export function Dashboard() {
  useEffect(() => {
    const currentURL = window.location.href;
    const urlParams = new URLSearchParams(new URL(currentURL).search);
    const codeValue = urlParams.get("code");
    if (codeValue) {
      localStorage.setItem("access_code", codeValue);
      window.location.replace("https://vertex.scaffm8.co.nz/")
    }
  }, []);

  return (
    <>
      <div className="min-h-screen flex justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img className="mx-auto h-24 w-auto" src={logo} alt="Workflow" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Welcome to the Vertex dashboard.
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}
