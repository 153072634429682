import React, { useState, useEffect } from "react";

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { Button as ButtonReact } from "primereact/button";
import "primeicons/primeicons.css";
import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.css";

import { Container } from "../../utils";

import { PageHeading, Badge, Spinner } from "../../common";
import { JobsApi } from "../../api";

const NOMINATIM_BASE_URL = "https://nominatim.openstreetmap.org/search?";

export const MapsMain = () => {
    const [points, setPoints] = useState(null);
    const [jobsStatus, setJobStatus] = useState(null);
    const [onHire, setOnHire] = useState(null);
    const jobsQuery = JobsApi.useJobs();
    jobsQuery.data=jobsQuery?.data?.filter(data=>data.status==="Active")
    useEffect(() => {
        if (!points && jobsQuery.data) {
            getCoordinates(jobsQuery.data, setPoints, jobsStatus, onHire);
        }
    }, [jobsQuery, jobsStatus, onHire]);

    if (jobsQuery.isLoading || !points) {
        return <div className="w-full h-48 flex justify-center items-center">
            <Spinner />
        </div>
    }

    const vehicleIconMap = new Icon({
        iconUrl: '/marker.svg',
        iconSize: [40, 40]
    });
    return (
        <>
            <PageHeading title="Jobs" />
            <>
                <div className="flex items-center ml-3">
                    <ButtonReact
                        type="button"
                        icon="pi pi-filter-slash"
                        label="Clear Filters"
                        className="p-button-outlined"
                        onClick={() => { setOnHire(null); setJobStatus(null); setPoints(null); }}
                    />
                    <ButtonReact
                        type="button"
                        label={`Job Status ${jobsStatus === null ? "" : jobsStatus}`}
                        className="p-button-outlined"
                        onClick={() => {
                            switch (jobsStatus) {
                                case null:
                                    setJobStatus("Approved");
                                    break;
                                case ("Pending Handover" || "Approved"):
                                    setJobStatus("Built & On Hire");
                                    break;
                                case ("In Progress" || "Built & On Hire"):
                                    setJobStatus("Completed");
                                    break;
                                case "Completed":
                                    setJobStatus("Off-Hired");
                                    break;
                                case ("Signed-off" || "Off-Hired"):
                                    setJobStatus(null);
                                    break;
                                default:
                                    setPoints(null);
                                    break;
                            }
                            setPoints(null);
                        }}
                    />
                    <ButtonReact
                        type="button"
                        label={`On Hire ${onHire === null ? "" : onHire}`}
                        className="p-button-outlined"
                        onClick={() => {
                            switch (onHire) {
                                case null:
                                    setOnHire("Yes");
                                    setJobStatus(null);
                                    break;
                                case "Yes":
                                    setOnHire("No");
                                    setJobStatus(null);
                                    break;
                                case "No":
                                    setOnHire(null);
                                    setJobStatus(null);
                                    break;
                                default:
                                    setJobStatus(null);
                                    break;
                            }
                        }}
                    />
                </div>
            </>
            <br />
            <Container>
                <MapContainer
                    style={{ height: "75vh", width: "100%" }}
                    center={[-36.84853, 174.76349]}
                    zoom="10" >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    {points.map((row, index) => (
                        row.jobs.length ? <Marker key={index} icon={vehicleIconMap} position={[row.latitude, row.longitude]}>
                            <Popup key={row.id}>
                                {

                                    row.jobs.map((job, index) => (
                                        <div key={index}>
                                            •Job Number: {job.job_num}
                                            <br />
                                            •Client: {job.clients?.client_name}
                                            <br />
                                            •Client Type: {job.clientType}
                                            <br />
                                            •Site: {job.site}
                                            <br />

                                            {
                                                index < row.jobs?.length - 1 && row.jobs?.length>1 ? <>
                                                                                            <br />
                                                                                            <hr />
                                                    <br /></> : null
                                            }
                                        </div>

                                    ))

                                }
                            </Popup>
                        </Marker> : null
                    ))}
                </MapContainer>
            </Container>
        </>
    );
};

async function getCoordinates(jobs, setPoints, jobsStatus, onHire) {
    const groupedJobs = jobs.reduce((acc, job) => {
        if (job.longitude !== null && job.latitude !== null) {
            const { latitude, longitude } = job;
            const key = `${latitude},${longitude}`;
            if (!acc[key]) {
                acc[key] = { latitude, longitude, jobs: [job] };
            } else {
                acc[key].jobs.push(job);
            }
        }
        return acc;

    }, {});

    const result = Object.values(groupedJobs);
    result.forEach(job => {
        if (onHire) {
            job.jobs = job.jobs.filter(e => e.on_hire === onHire);
        }
        if (jobsStatus) {
            job.jobs = job.jobs.filter(e => e.job_status === jobsStatus);
        }
    });

    setPoints(result);
}